import styled from 'styled-components'
import imgCorrect from './img/correct.svg'
import imgIncorrect from './img/incorrect.svg'

const Result = ({ gameStatus, reason, isCorrect }) => {
  return (
    <Wrapper gameStatus={gameStatus}>
      <img alt="result" src={isCorrect ? imgCorrect : imgIncorrect} />
      <Reason>{gameStatus === "answer" && reason}</Reason>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  height: 0;
  position: absolute;
  bottom: 4.5rem;
  transition: all 0.32s var(--transition-ease-out-quint);
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-items: center;
  > img {
    margin-bottom: 0.5rem;
    border-radius: 50%;
    box-shadow: var(--elevation-result);
  }
  opacity: ${props => props.gameStatus === "answer" ? "100%" : "0"};;
  @media (max-width: 840px) {
    position: relative;
    bottom: 9.25rem;
  }
`
const Reason = styled.p`
  font-family: var(--font-stack);
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.25rem;
  text-align: center;
  color: var(--primary);
`

export default Result