const getPercentCorrect = (level, correct, gameStatus) => {
  let percentCorrect = 0

  if (level === 0 && correct === 0) {
    percentCorrect = 0
  } else if (level === 0 && correct === 1) {
    percentCorrect = 100
  } else if (gameStatus === 'choose') {
    percentCorrect = Math.floor((correct / level) * 100)
  } else if (gameStatus === 'answer' || gameStatus === 'end') {
    percentCorrect = Math.floor((correct / (level + 1)) * 100)
  }

  return percentCorrect
}

export default getPercentCorrect