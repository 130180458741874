import styled from 'styled-components'
import imgUser from './img/user.svg'

const GameResult = ({ score }) => {
  let scoreCategory = 1

    if (score >= 20 && score < 40) {
      scoreCategory = 2
    } else if (score >= 40 && score < 60) {
      scoreCategory = 3
    } else if (score >= 60 && score < 80) {
      scoreCategory = 4
    } else if (score >= 80) {
      scoreCategory = 5
    }

  return (
    <Wrapper>
      <Step>
        <Bar />
        <Label isActive={scoreCategory === 1}>Poor</Label>
      </Step>
      <Step>
        <Bar />
        <Label isActive={scoreCategory === 2}>Fair</Label>
      </Step>
      <Step>
        <Bar />
        <Label isActive={scoreCategory === 3}>Average</Label>
      </Step>
      <Step>
        <Bar />
        <Label isActive={scoreCategory === 4}>Good</Label>
      </Step>
      <Step>
        <Bar />
        <Label isActive={scoreCategory === 5}>Excellent</Label>
      </Step>
      <User src={imgUser} score={score}/>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  width: 39.5rem;
  display: flex;
  gap: 0.5rem;
  margin: 0 3rem 1rem 3rem;
  @media (max-width: 840px) {
    width: calc(100% - 3rem);
  }
`
const Step = styled.div`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  flex-basis: 0;
  gap: 1rem;
  &:nth-child(1) div {
    background-color: var(--progress-negative);
  }
  &:nth-child(2) div {
    background-color: #F68D51;
  }
  &:nth-child(3) div {
    background-color: var(--brand-primary);
  }
  &:nth-child(4) div {
    background-color: #83D642;
  }
  &:nth-child(5) div {
    background-color: var(--progress-positive);
  }
  &:nth-child(category) {
    opacity: 0;
  }
`
const Bar = styled.div`
  width: 7.5rem;
  height: 0.75rem;
  border-radius: 0.75rem;
  background-color: var(--brand-primary);
  @media (max-width: 840px) {
    width: 100%;
  }
`
const Label = styled.p`
  font-family: var(--font-stack);
  font-weight: 600;
  font-size: 0.75rem;
  line-height: 1.25rem;
  text-align: center;
  color: ${props => props.isActive ? 'var(--secondary)' : 'var(--tertiary)'};
  @media (max-width: 840px) {
    flex-grow: 1;
  }
`
const User = styled.img`
  position: absolute;
  left: ${props => props.score + '%'};
  border-radius: 50%;
  transform: translate(-50%, -0.625rem);
  box-shadow: var(--elevation-result);
`

export default GameResult