import styled from 'styled-components'

const Title = ({ gameStatus, isComparing, toggleCompare, correct, score }) => {
  return (
    <TitleWrapper>
      <Headline>qUIz</Headline>
      <SubheaderDesktop>
        {gameStatus === 'choose' && 'Click or use the keyboard shortcuts to select the correct design.'}
        {gameStatus === 'answer' && 'Click anywhere or use any of the keyboard shortcuts to continue.'}
        {gameStatus === 'end' && `You got `}
        {gameStatus === 'end' && <span>{correct}</span>}
        {gameStatus === 'end' && ` out of `}
        {gameStatus === 'end' && <span>48</span>}
        {gameStatus === 'end' && ` correct (`}
        {gameStatus === 'end' && <span>{score}</span>}
        {gameStatus === 'end' && `%)`}
      </SubheaderDesktop>
      <SubheaderMobile>
        {gameStatus === 'choose' && 'Tap to select the correct design.'}
        {gameStatus === 'answer' && 'Tap anywhere to continue.'}
        {gameStatus === 'end' && `You got `}
        {gameStatus === 'end' && <span>{correct}</span>}
        {gameStatus === 'end' && ` out of `}
        {gameStatus === 'end' && <span>48</span>}
        {gameStatus === 'end' && ` correct (`}
        {gameStatus === 'end' && <span>{score}</span>}
        {gameStatus === 'end' && `%)`}
      </SubheaderMobile>
      <MobileCompareButton gameStatus={gameStatus} isComparing={isComparing} onClick={toggleCompare}>Compare</MobileCompareButton>
    </TitleWrapper>
  )
}

const TitleWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 48rem;
  padding: 1rem 1rem 3rem 1rem;
  @media (max-width: 840px) {
    width: 100%;
    padding: 2rem 1rem;
  }
`
const Headline = styled.h1`
  font-family: var(--font-stack);
  font-size: 3.375rem;
  line-height: 3.375rem;
  font-weight: 800;
  letter-spacing: -0.01875rem;
  text-align: center;
  color: var(--primary);
  margin-bottom: 1.5rem;
  @media (max-width: 840px) {
    font-size: 2.5rem;
    line-height: 2.5rem;
    margin-bottom: 0.75rem;
  }
`
const SubheaderDesktop = styled.p`
  font-family: var(--font-stack);
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  text-align: center;
  color: var(--primary);
  @media (max-width: 840px) {
    display: none;
  }
  > span {
    font-weight: 700;
  }
`
const SubheaderMobile = styled.p`
  font-family: var(--font-stack);
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  text-align: center;
  color: var(--primary);
  @media (min-width: 841px) {
    display: none;
  }
  > span {
    font-weight: 700;
  }
`
const MobileCompareButton = styled.div`
  position: absolute;
  bottom: -2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100vw - 3rem);
  height: 2.5rem;
  background: var(--wash-white);
  box-shadow: var(--elevation-card);
  border-radius: 0.5rem;
  cursor: pointer;
  font-family: var(--font-stack);
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 0.875rem;
  letter-spacing: -0.00625rem;
  color: var(--primary);
  transition: all 0.32s var(--transition-ease-out-quint);
  opacity: ${props => props.gameStatus === "answer" ? "100%" : "0"};
  z-index: 2;
  ${props => props.isComparing && `
    background: var(--primary);
    color: var(--wash-white);
  `}
  @media (min-width: 841px) {
    display: none;
  }
`

export default Title