import styled from 'styled-components'
import getPercentCorrect from '../utils/getPercentCorrect'
import logo from './logo.svg'

const Header = ({ level, correct, gameStatus }) => {
  let percentCorrect = getPercentCorrect(level, correct, gameStatus)

  let correctCountColor = 'var(--primary)'
  
  if (percentCorrect > 0 && percentCorrect < 50) {
    correctCountColor = 'var(--progress-negative)'
  } else if (percentCorrect > 50) {
    correctCountColor = 'var(--progress-positive)'
  }

  const currentLevel = level <= 48 ? level + 1 : 48

  return (
    <StyledHeader>
        <PageTitle>
          <a href='https://designfoundations.com/' target='blank'>
            <StyledLogo src={logo} />
          </a>
          <TitleDesktop>Design Foundations Games</TitleDesktop>
          <TitleMobile>DF Games</TitleMobile>
        </PageTitle>
      <div>
        <ProgressText>
          <LevelCount>{currentLevel} of 48</LevelCount>
          <CorrectCount color={correctCountColor}>{percentCorrect}% correct</CorrectCount>
        </ProgressText>
        <ProgressBar>
          <ProgressBarStage>
            <ProgressBarFill level={currentLevel}/>
          </ProgressBarStage>
          <ProgressBarStage>
            <ProgressBarFill level={currentLevel - 16}/>
          </ProgressBarStage>
          <ProgressBarStage>
            <ProgressBarFill level={currentLevel - 32}/>
          </ProgressBarStage>
        </ProgressBar>
      </div>
    </StyledHeader>
  )
}

const StyledHeader = styled.header`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;
  background-color: var(--wash-white);
  height: 4rem;
  width: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  box-shadow: var(--elevation-header);
  font-family: var(--font-stack);
`
const PageTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`
const StyledLogo = styled.img`
  width: 2rem;
  height: 2rem;
  margin-right: 1rem;
`
const TitleDesktop = styled.h3`
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 700;
  color: #323437;
  @media (max-width: 840px) {
    display: none;
  }
`
const TitleMobile = styled.h3`
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 700;
  color: #323437;
  @media (min-width: 841px) {
    display: none;
  }
`
const ProgressText = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 0.5rem;
  @media (max-width: 840px) {
    flex-direction: column;
  }
`
const LevelCount = styled.p`
  font-size: 0.75rem;
  line-height: 0.75rem;
  font-weight: 600;
  text-align: right;
  color: var(--primary);
  @media (max-width: 840px) {
    margin-bottom: 0.5rem;
  }
`
const CorrectCount = styled.p`
  font-size: 0.75rem;
  line-height: 0.75rem;
  font-weight: 600;
  text-align: right;
  color: ${props => props.color}
`
const ProgressBar = styled.div`
  display: flex;
  flex-direction: row;
  position: relative;
  @media (max-width: 840px) {
    display: none;
  }
`
const ProgressBarStage = styled.div`
  position: relative;
  width: 4.5rem;
  height: 0.75rem;
  background-color: var(--divider);
  overflow: hidden;
  &:nth-child(1) {
    border-radius: 0.5rem 0 0 0.5rem;
    margin-right: 0.125rem;
  }
  &:nth-child(2) {
    border-radius: 0 0 0 0;
    margin-right: 0.125rem;
  }
  &:nth-child(3) {
    border-radius: 0 0.5rem 0.5rem 0;
  }
`
const ProgressBarFill = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background-color: var(--brand-primary);
  height: 0.75rem;
  width: ${props => (props.level < 16 ? (props.level * 100 / 16) : 100)  + '%' };
  transition: all 0.16s ease-out;
`

export default Header