import styled from 'styled-components'

const ContinueMessage = ({ gameStatus, handleMockClick }) => {
  return (
    <>
      <Message gameStatus={gameStatus}>
        Press<KeyboardButton isSquare={true}>C</KeyboardButton> to compare <Divider>•</Divider> Press<KeyboardButton>↵ Enter</KeyboardButton>to continue
      </Message>
      <MobileWrapper>
        <MobileNextButton gameStatus={gameStatus} onClick={handleMockClick}>Next</MobileNextButton>
      </MobileWrapper>
    </>
  )  
}

const Message = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-family: var(--font-stack);
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: var(--secondary);
  position: absolute;
  bottom: 2rem;
  transition: all 0.32s var(--transition-ease-out-quint);
  opacity: ${props => props.gameStatus === "answer" ? "100%" : "0"};
  @media (max-width: 840px) {
    display: none;
  }
`
const KeyboardButton = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0.5rem 0.75rem 0.5rem 0.625rem;
  gap: 0.25rem;
  background: var(--wash-white);
  border: 0.0625rem solid var(--divider);
  box-shadow: var(--elevation-card);
  border-radius: 6px;
  font-weight: 700;
  ${props => props.isSquare && 'padding: 0.5rem 0.75rem'}
`
const Divider = styled.div`
  margin: 0 0.5rem;
  color: var(--tertiary);
`
const MobileWrapper = styled.div`
  height: 3rem;
`
const MobileNextButton = styled.div`
  transform: translateY(-1rem);
  display: flex;
  justify-content: center;
  align-items: center;
  width: calc(100vw - 3rem);
  height: 2.5rem;
  margin-bottom: 1.5rem;
  background: var(--brand-primary);
  box-shadow: var(--elevation-card);
  border-radius: 0.5rem;
  cursor: pointer;
  font-family: var(--font-stack);
  font-weight: 600;
  font-size: 0.875rem;
  line-height: 0.875rem;
  letter-spacing: -0.00625rem;
  color: var(--primary);
  transition: all 0.32s var(--transition-ease-out-quint);
  opacity: ${props => props.gameStatus === "answer" ? "100%" : "0"};
  z-index: 2;
  ${props => props.isComparing && `
    background: var(--primary);
    color: var(--wash-white);
  `}
  @media (min-width: 841px) {
    display: none;
  }
`
export default ContinueMessage