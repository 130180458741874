import { useState } from 'react'
import ReactGA from 'react-ga4'
import useHotkeys from '@reecelucas/react-use-hotkeys'
import styled from 'styled-components'
import Title from './Title'
import { ShortcutGroup1, ShortcutGroup2 } from './ShortcutGroup'
import Result from './Result'
import ContinueMessage from './ContinueMessage'
import GameResult from './GameResult'
import ShareResult from './ShareResult'

const Game = ({ totalQuestions, level, setLevel, correct, setCorrect, gameStatus, setGameStatus, data, score }) => {
  const [ isLastChoiceCorrect, setIsLastChoiceCorrect ] = useState(null)
  const [ isComparing, setIsComparing ] = useState(false)
  const correctImage = data.correctImage

  const handleMockClick = (selection) => {
    if (gameStatus === 'choose') {
      if (selection === correctImage) {
        setCorrect(prevCorrect => prevCorrect + 1)
        setIsLastChoiceCorrect(true)
      } else if (selection !== correctImage) {
        setIsLastChoiceCorrect(false)
      }
      setGameStatus('answer')
    } else if (gameStatus === 'answer') {
      if (level === totalQuestions - 1) {
        setGameStatus('end')
        ReactGA.event({
          category: 'game_end',
          action: 'game_end',
          label: 'score',
          value: score,
        })
      } else {
        setGameStatus('choose')
        setLevel(prevLevel => prevLevel + 1)
      }
    }
  }

  useHotkeys(['1', 'ArrowLeft'], () => handleMockClick(1))
  useHotkeys(['2', 'ArrowRight'], () => handleMockClick(2))
  useHotkeys(['Enter'], () => {
    if (gameStatus === 'answer') {
      setGameStatus('choose')
      setLevel(prevLevel => prevLevel + 1)
    }
  })

  const toggleCompare = () => {
    if (gameStatus === 'answer') {
      setIsComparing(prevIsComparing => !prevIsComparing)
    }
  }

  useHotkeys(['c'], () => {
    toggleCompare()
  })

  return (
    <BodyWrapper>
      <Title gameStatus={gameStatus} isComparing={isComparing} toggleCompare={toggleCompare} correct={correct} score={score}/>
      {gameStatus !== 'end' &&
        <GameWrapper>
          <Column gameStatus={gameStatus} isCorrect={correctImage === 1} isComparing={isComparing}>
            <Mock
              className="mock"
              style={{ backgroundImage: `url(${data.image1})` }}
              onClick={() => handleMockClick(1)}
            />
            <ShortcutGroup1 gameStatus={gameStatus} />
          </Column>
          <Column gameStatus={gameStatus} isCorrect={correctImage === 2} isComparing={isComparing}>
            <Mock
              className="mock"
              style={{ backgroundImage: `url(${data.image2})` }}
              onClick={() => handleMockClick(2)}
            />
            <ShortcutGroup2 gameStatus={gameStatus} />
          </Column>
          <Result gameStatus={gameStatus} reason={data.reason} isCorrect={isLastChoiceCorrect}/>
        </GameWrapper>
      }
      {gameStatus === 'end' &&
        <>
          <GameResult score={score} />
          <ShareResult score={score} />
        </>
      }
      <ContinueMessage gameStatus={gameStatus} handleMockClick={handleMockClick}/>
    </BodyWrapper>
  )
}
const BodyWrapper = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (min-width: 841px) {
    padding: 4rem 2rem 2rem 2rem;
  }
  @media (max-width: 840px) {
    padding-top: 4rem;
    justify-content: flex-start;
  }
`
const GameWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  @media (max-width: 840px) {
    width: 100%;
    flex-direction: column;
  }
`
const Column = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 100%;
  transition: all 0.32s var(--transition-ease-out-quint);
  &:nth-child(1) {
    margin-right: 0.75rem;
    > .mock {
      ${props => props.gameStatus === 'answer' && 'transform: translateX(calc(50% + 0.75rem));'}
      z-index: ${props => props.isCorrect && !props.isComparing ? 1 : 0};
    }
  }
  &:nth-child(2) {
    margin-left: 0.75rem;
    > .mock {
      ${props => props.gameStatus === 'answer' && 'transform: translateX(calc(-50% + -0.75rem))'}
    }
  }
  @media (max-width: 840px) {
    &:nth-child(1) {
      margin-right: 0;
      > .mock {
        ${props => props.gameStatus === 'answer' && 'transform: translateY(calc(50% + 0.5rem));'}
      }
    }
    &:nth-child(2) {
      margin-left: 0;
      > .mock {
        ${props => props.gameStatus === 'answer' && 'transform: translateY(calc(-50% - 0.5rem))'}
      }
    }
  }
`
const Mock = styled.div`
  width: 23.4375rem;
  height: 15.625rem;
  background-size: cover;
  border-radius: 0.5rem;
  max-width: 23.4375rem;
  box-shadow: var(--elevation-card);
  cursor: pointer;
  margin-bottom: 1.5rem;
  position: relative;
  transition: transform 0.32s 0.08s var(--transition-ease-out-quint), background-image 0.16s var(--transition-ease-in-out);
  &:hover {
    transform: translateY(-0.5rem);
  }
  @media (max-width: 840px) {
    width: 20.4375rem;
    height: 13.625rem;
    margin-bottom: 1rem;
  }
  @media (max-width: 400px) {
    max-width: 20.4375rem;
  }
`


export default Game