import styled from 'styled-components'
import imgFacebook from './img/facebook.svg'
import imgLinkedin from './img/linkedin.svg'
import imgTwitter from './img/twitter.svg'

const ShareResult = ({ score }) => {

  
  return (
    <Wrapper>
      <Label>Share your result:</Label>
      <SocialWrapper>
        <SocialLink target='_blank' href={`https://twitter.com/intent/tweet?text=I%20scored%20${score}%25%20in%20this%20game%20on%20Design%20Foundations%3A%20https%3A//games.designfoundations.com/`}>
          <img alt='Share to Twitter' src={imgTwitter} />
        </SocialLink>
        <SocialLink target='_blank' href={`https://www.facebook.com/sharer/sharer.php?u=https%3A//games.designfoundations.com/`}>
          <img alt='Share to Facebook' src={imgFacebook} />
        </SocialLink>
        <SocialLink target='_blank' href={`https://www.linkedin.com/shareArticle?url=https://games.designfoundations.com/&title=Design%20Foundations%20Games`}>
          <img alt='Share to Linkedin' src={imgLinkedin} />
        </SocialLink>
      </SocialWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  bottom: 2rem;
  padding: 1rem;
`
const Label = styled.p`
  font-family: var(--font-stack);
  font-weight: 400;
  font-size: 1rem;
  line-height: 1.75rem;
  text-align: center;
  color: var(--primary);
  margin-bottom: 1.5rem;
`
const SocialWrapper = styled.div`
  display: flex;
  flex-direction: row;
`
const SocialLink = styled.a`
  display: block;
  padding: 1rem;
  cursor: pointer;
  img {
    transition: transform 0.16s var(--transition-ease-out-quint);
  }
  :hover {
    img {
      transform: scale(1.25);
    }
  }
  @media (max-width: 840px) {
    img {
      width: 1.75rem;
      height: 1.75rem;
    }
  }
`

export default ShareResult