import l01correct from './img/01-correct@2x.png'
import l01incorrect from './img/01-incorrect@2x.png'
import l02correct from './img/02-correct@2x.png'
import l02incorrect from './img/02-incorrect@2x.png'
import l03correct from './img/03-correct@2x.png'
import l03incorrect from './img/03-incorrect@2x.png'
import l04correct from './img/04-correct@2x.png'
import l04incorrect from './img/04-incorrect@2x.png'
import l05correct from './img/05-correct@2x.png'
import l05incorrect from './img/05-incorrect@2x.png'
import l06correct from './img/06-correct@2x.png'
import l06incorrect from './img/06-incorrect@2x.png'
import l07correct from './img/07-correct@2x.png'
import l07incorrect from './img/07-incorrect@2x.png'
import l08correct from './img/08-correct@2x.png'
import l08incorrect from './img/08-incorrect@2x.png'
import l09correct from './img/09-correct@2x.png'
import l09incorrect from './img/09-incorrect@2x.png'
import l10correct from './img/10-correct@2x.png'
import l10incorrect from './img/10-incorrect@2x.png'
import l11correct from './img/11-correct@2x.png'
import l11incorrect from './img/11-incorrect@2x.png'
import l12correct from './img/12-correct@2x.png'
import l12incorrect from './img/12-incorrect@2x.png'
import l13correct from './img/13-correct@2x.png'
import l13incorrect from './img/13-incorrect@2x.png'
import l14correct from './img/14-correct@2x.png'
import l14incorrect from './img/14-incorrect@2x.png'
import l15correct from './img/15-correct@2x.png'
import l15incorrect from './img/15-incorrect@2x.png'
import l16correct from './img/16-correct@2x.png'
import l16incorrect from './img/16-incorrect@2x.png'
import l17correct from './img/17-correct@2x.png'
import l17incorrect from './img/17-incorrect@2x.png'
import l18correct from './img/18-correct@2x.png'
import l18incorrect from './img/18-incorrect@2x.png'
import l19correct from './img/19-correct@2x.png'
import l19incorrect from './img/19-incorrect@2x.png'
import l20correct from './img/20-correct@2x.png'
import l20incorrect from './img/20-incorrect@2x.png'
import l21correct from './img/21-correct@2x.png'
import l21incorrect from './img/21-incorrect@2x.png'
import l22correct from './img/22-correct@2x.png'
import l22incorrect from './img/22-incorrect@2x.png'
import l23correct from './img/23-correct@2x.png'
import l23incorrect from './img/23-incorrect@2x.png'
import l24correct from './img/24-correct@2x.png'
import l24incorrect from './img/24-incorrect@2x.png'
import l25correct from './img/25-correct@2x.png'
import l25incorrect from './img/25-incorrect@2x.png'
import l26correct from './img/26-correct@2x.png'
import l26incorrect from './img/26-incorrect@2x.png'
import l27correct from './img/27-correct@2x.png'
import l27incorrect from './img/27-incorrect@2x.png'
import l28correct from './img/28-correct@2x.png'
import l28incorrect from './img/28-incorrect@2x.png'
import l29correct from './img/29-correct@2x.png'
import l29incorrect from './img/29-incorrect@2x.png'
import l30correct from './img/30-correct@2x.png'
import l30incorrect from './img/30-incorrect@2x.png'
import l31correct from './img/31-correct@2x.png'
import l31incorrect from './img/31-incorrect@2x.png'
import l32correct from './img/32-correct@2x.png'
import l32incorrect from './img/32-incorrect@2x.png'
import l33correct from './img/33-correct@2x.png'
import l33incorrect from './img/33-incorrect@2x.png'
import l34correct from './img/34-correct@2x.png'
import l34incorrect from './img/34-incorrect@2x.png'
import l35correct from './img/35-correct@2x.png'
import l35incorrect from './img/35-incorrect@2x.png'
import l36correct from './img/36-correct@2x.png'
import l36incorrect from './img/36-incorrect@2x.png'
import l37correct from './img/37-correct@2x.png'
import l37incorrect from './img/37-incorrect@2x.png'
import l38correct from './img/38-correct@2x.png'
import l38incorrect from './img/38-incorrect@2x.png'
import l39correct from './img/39-correct@2x.png'
import l39incorrect from './img/39-incorrect@2x.png'
import l40correct from './img/40-correct@2x.png'
import l40incorrect from './img/40-incorrect@2x.png'
import l41correct from './img/41-correct@2x.png'
import l41incorrect from './img/41-incorrect@2x.png'
import l42correct from './img/42-correct@2x.png'
import l42incorrect from './img/42-incorrect@2x.png'
import l43correct from './img/43-correct@2x.png'
import l43incorrect from './img/43-incorrect@2x.png'
import l44correct from './img/44-correct@2x.png'
import l44incorrect from './img/44-incorrect@2x.png'
import l45correct from './img/45-correct@2x.png'
import l45incorrect from './img/45-incorrect@2x.png'
import l46correct from './img/46-correct@2x.png'
import l46incorrect from './img/46-incorrect@2x.png'
import l47correct from './img/47-correct@2x.png'
import l47incorrect from './img/47-incorrect@2x.png'
import l48correct from './img/48-correct@2x.png'
import l48incorrect from './img/48-incorrect@2x.png'

const gameData = [
  {
    image1: l01correct,
    image2: l01incorrect,
    correctImage: 1,
    reason: 'The bubble colors should be different for different users.'
  },
  {
    image1: l02correct,
    image2: l02incorrect,
    correctImage: 1,
    reason: 'The caret icon is missing from the secondary button.'
  },
  {
    image1: l03incorrect,
    image2: l03correct,
    correctImage: 2,
    reason: 'The play count color is too light.'
  },
  {
    image1: l04correct,
    image2: l04incorrect,
    correctImage: 1,
    reason: 'The dates have inconsistent formats.'
  },
  {
    image1: l05incorrect,
    image2: l05correct,
    correctImage: 2,
    reason: 'There’s too much padding on the right side of the bubbles.'
  },
  {
    image1: l06incorrect,
    image2: l06correct,
    correctImage: 2,
    reason: 'There’s too much spacing between the buttons.'
  },
  {
    image1: l07incorrect,
    image2: l07correct,
    correctImage: 2,
    reason: 'Popular is misspelled.'
  },
  {
    image1: l08incorrect,
    image2: l08correct,
    correctImage: 2,
    reason: 'The search input text should use sentence capitalization.'
  },
  {
    image1: l09correct,
    image2: l09incorrect,
    correctImage: 1,
    reason: 'The grey bubble’s text should be left-aligned.'
  },
  {
    image1: l10incorrect,
    image2: l10correct,
    correctImage: 2,
    reason: 'The primary button text is all lowercase.'
  },
  {
    image1: l11incorrect,
    image2: l11correct,
    correctImage: 2,
    reason: 'The play count font weight is too light.'
  },
  {
    image1: l12correct,
    image2: l12incorrect,
    correctImage: 1,
    reason: 'The star icon is rotated.'
  },
  {
    image1: l13correct,
    image2: l13incorrect,
    correctImage: 1,
    reason: 'The text color is too light, and may cause legibility issues.'
  },
  {
    image1: l14correct,
    image2: l14incorrect,
    correctImage: 1,
    reason: 'The metric label font weight is too light.'
  },
  {
    image1: l15correct,
    image2: l15incorrect,
    correctImage: 1,
    reason: 'The first action menu icon is missing a dot.'
  },
  {
    image1: l16correct,
    image2: l16incorrect,
    correctImage: 1,
    reason: 'The author icons aren’t aligned with each other.'
  },
  {
    image1: l17incorrect,
    image2: l17correct,
    correctImage: 2,
    reason: 'The font weight is too light, and may cause legibility issues.'
  },
  {
    image1: l18correct,
    image2: l18incorrect,
    correctImage: 1,
    reason: 'The profile image is low resolution.'
  },
  {
    image1: l19incorrect,
    image2: l19correct,
    correctImage: 2,
    reason: 'The play counts are missing commas.'
  },
  {
    image1: l20incorrect,
    image2: l20correct,
    correctImage: 2,
    reason: 'The word ‘mail’ is misspelled.'
  },
  {
    image1: l21correct,
    image2: l21incorrect,
    correctImage: 1,
    reason: 'The grey bubble’s line height is too large.'
  },
  {
    image1: l22correct,
    image2: l22incorrect,
    correctImage: 1,
    reason: 'The primary button’s corners are less rounded than those of the secondary button.'
  },
  {
    image1: l23correct,
    image2: l23incorrect,
    correctImage: 1,
    reason: 'The action menu icon size is disproportionately large.'
  },
  {
    image1: l24incorrect,
    image2: l24correct,
    correctImage: 2,
    reason: 'The important icons are different colors.'
  },
  {
    image1: l25incorrect,
    image2: l25correct,
    correctImage: 2,
    reason: 'The avatar image is low resolution.'
  },
  {
    image1: l26correct,
    image2: l26incorrect,
    correctImage: 1,
    reason: 'One of the divider elements is missing.'
  },
  {
    image1: l27incorrect,
    image2: l27correct,
    correctImage: 2,
    reason: 'The track text elements aren’t vertically aligned with the album artwork.'
  },
  {
    image1: l28correct,
    image2: l28incorrect,
    correctImage: 1,
    reason: 'The search input text isn’t vertically-centered.'
  },
  {
    image1: l29incorrect,
    image2: l29correct,
    correctImage: 2,
    reason: 'Both bubbles have inconsistent padding on the left compared to the right.'
  },
  {
    image1: l30correct,
    image2: l30incorrect,
    correctImage: 1,
    reason: 'The handle text isn’t center-aligned.'
  },
  {
    image1: l31incorrect,
    image2: l31correct,
    correctImage: 2,
    reason: 'The title and play count are too close together.'
  },
  {
    image1: l32correct,
    image2: l32incorrect,
    correctImage: 1,
    reason: 'The search input’s corners have inconsistent rounding on the left and right.'
  },
  {
    image1: l33incorrect,
    image2: l33correct,
    correctImage: 2,
    reason: 'The date and name text elements are too light.'
  },
  {
    image1: l34incorrect,
    image2: l34correct,
    correctImage: 2,
    reason: 'The followers count text is darker than following and likes.'
  },
  {
    image1: l35correct,
    image2: l35incorrect,
    correctImage: 1,
    reason: 'The second track’s artwork is smaller than that of the other tracks.'
  },
  {
    image1: l36correct,
    image2: l36incorrect,
    correctImage: 1,
    reason: 'The user icon in the search input isn’t vertically-centered.'
  },
  {
    image1: l37correct,
    image2: l37incorrect,
    correctImage: 1,
    reason: 'There’s too much margin between the bubbles compared to the date.'
  },
  {
    image1: l38correct,
    image2: l38incorrect,
    correctImage: 1,
    reason: 'The profile and handle elements aren’t center-aligned.'
  },
  {
    image1: l39incorrect,
    image2: l39correct,
    correctImage: 2,
    reason: 'The third track’s artwork isn’t aligned with those of the other tracks.'
  },
  {
    image1: l40correct,
    image2: l40incorrect,
    correctImage: 1,
    reason: 'The mail body text line height is too tight.'
  },
  {
    image1: l41incorrect,
    image2: l41correct,
    correctImage: 2,
    reason: 'The grey bubble is missing a tail.'
  },
  {
    image1: l42correct,
    image2: l42incorrect,
    correctImage: 1,
    reason: 'The likes element isn’t aligned with followers and following.'
  },
  {
    image1: l43incorrect,
    image2: l43correct,
    correctImage: 2,
    reason: 'The margin between tracks is inconsistent.'
  },
  {
    image1: l44correct,
    image2: l44incorrect,
    correctImage: 1,
    reason: 'The author icons are different sizes.'
  },
  {
    image1: l45correct,
    image2: l45incorrect,
    correctImage: 1,
    reason: 'The blue bubble’s corners are more rounded than those of the grey bubble.'
  },
  {
    image1: l46correct,
    image2: l46incorrect,
    correctImage: 1,
    reason: 'The primary button text isn’t center-aligned.'
  },
  {
    image1: l47incorrect,
    image2: l47correct,
    correctImage: 2,
    reason: 'The track number isn’t aligned with the section title.'
  },
  {
    image1: l48correct,
    image2: l48incorrect,
    correctImage: 1,
    reason: 'The author icon is rotated.'
  },
]

export default gameData