import styled from 'styled-components'

const ShortcutGroup1 = ({ gameStatus }) => {
  return (
    <Wrapper gameStatus={gameStatus}>
      <Shortcut type='arrow'>←</Shortcut>
      <Or>or</Or>
      <Shortcut>1</Shortcut>
    </Wrapper>
  )
}

const ShortcutGroup2 = ({ gameStatus }) => {
  return (
    <Wrapper gameStatus={gameStatus}>
      <Shortcut>2</Shortcut>
      <Or>or</Or>
      <Shortcut type='arrow'>→</Shortcut>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  direction: row;
  justify-content: space-between;
  align-items: center;
  transition: all 0.32s var(--transition-ease-out-quint);
  opacity: ${props => props.gameStatus === "choose" ? "100%" : "0"};
  @media (max-width: 840px) {
    display: none;
  }
`
const Shortcut = styled.div`
  width: 2rem;
  height: 2rem;
  background: var(--wash-white);
  color: var(--secondary);
  font-family: var(--font-stack);
  font-size: 1rem;
  line-height: 1rem;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  box-shadow: var(--elevation-card);
  ${props => props.type === 'arrow' && `padding-bottom: 4px;`}
`
const Or = styled.p`
  font-family: var(--font-stack);
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  color: var(--secondary);
  margin: 0 0.5rem;
`

export { ShortcutGroup1, ShortcutGroup2 }