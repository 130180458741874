import { useState } from 'react'
import ReactGA from 'react-ga4'
import styled from 'styled-components'
import Header from './header/Header'
import Game from './game/Game'
import gameData from './game/gameData'
import getPercentCorrect from './utils/getPercentCorrect'

ReactGA.initialize('G-SH88P9VN5H')
ReactGA.set({ anonymizeIp: true, allowAdFeatures: false })

function App() {
  const [level, setLevel] = useState(0)
  const [correct, setCorrect] = useState(0)
  const [gameStatus, setGameStatus] = useState('choose') // choose, answer, end

  const score = getPercentCorrect(level, correct, gameStatus)
  const totalQuestions = gameData.length

  return (
    <AppWrapper>
      <Header
        level={level}
        correct={correct}
        gameStatus={gameStatus}
      />
      <Game
        totalQuestions={totalQuestions}
        level={level}
        setLevel={setLevel}
        correct={correct}
        setCorrect={setCorrect}
        gameStatus={gameStatus}
        setGameStatus={setGameStatus}
        data={gameData[level]}
        score={score}
      />
    </AppWrapper>
  );
}

const AppWrapper = styled.div`
  height: calc(100% - 64px);
  min-height: 100%;
`

export default App;